<template>
  <div class="meter-read-box top-padding">
    <top-home title="手动抄表"></top-home>
    <div class="top-box">
      <div class="top-search" @click="searchPop=true">
        <van-icon name="search" />
      </div>
    </div>
    <div class="read-num-box">
      <div class="num-item">
        <div class="item-num">{{readNumObj.totalCou}}</div>
        <div class="item-text">应抄表数</div>
      </div>
      <div class="num-item">
        <div class="item-num">{{readNumObj.ycCou}}</div>
        <div class="item-text">已抄表数</div>
      </div>
      <div class="num-item">
        <div class="item-num">{{readNumObj.wcCou}}</div>
        <div class="item-text">未抄表数</div>
      </div>
    </div>
    <div class="read-list-box">
      <div class="read-null" v-if="manualData.length<=0">
        <img src="@/assets/PersonalCenter/PointsCenter/noDataPng.png" alt width="60%" />
        <div class="null-text">暂无数据</div>
      </div>
      <div class="manual-list-box" v-else>
        <div
          class="list-item"
          v-for="item in manualData"
          :key="item.id"
          @click="redirectsFun(item)"
        >
          <div class="item-icon">
            <van-icon name="column" />
          </div>
          <div class="item-content">
            <div class="content-text">{{item.cbscTitle}}</div>
            <div class="content-tip">
              未抄表数：
              <span class="tip-num">{{item.wcCou}}</span>
            </div>
          </div>
          <div class="item-arrow">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="searchPop" position="right" :style="{ width:'90%',height: '100%' }">
      <div class="search-pop-box">
        <div class="pop-title">
          用户查询
          <!-- <div class="title-icon">
            <van-icon name="cross" />
          </div>-->
        </div>
        <van-search
          v-model="searchValue"
          show-action
          placeholder="请输入 姓名/户号"
          @search="searchWaterReadFun"
        >
          <template #action>
            <div @click="searchWaterReadFun">搜索</div>
          </template>
        </van-search>
        <div class="list-null" v-if="searchData.length<=0">{{isSearch?'未查询到需要抄表的用户':'请先查询'}}</div>
        <div class="search-list" v-else>
          <meter-list ref="meterList" :listData="searchData" :refreshFun="refreshFun.bind(this)"></meter-list>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getCbyManualListApi, getCbyWaterListApi } from '@/reuqest/api'
import MeterList from '@/components/WaterMeter/meter-list.vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    MeterList,
  },
  data() {
    return {
      readNumObj: {
        totalCou: 0,
        ycCou: 0,
        wcCou: 0,
      },
      manualData: [],
      searchPop: false,
      searchValue: '',
      isSearch: false,
      searchData: [],
    }
  },
  watch: {
    searchPop(newVal) {
      if (!newVal && this.$refs.meterList) {
        this.$refs.meterList.readItemPop = false
      }
    },
  },
  mounted() {
    this.getManualDataFun()
  },
  methods: {
    ...mapMutations(['SET_CURR_MANUAL']),
    getManualDataFun() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      getCbyManualListApi().then((res) => {
        this.manualData = res.data
        let totalCou = 0
        let ycCou = 0
        let wcCou = 0
        this.manualData.forEach((item) => {
          totalCou = totalCou + item.totalCou
          ycCou = ycCou + item.ycCou
          wcCou = wcCou + item.wcCou
        })
        this.readNumObj = {
          totalCou,
          ycCou,
          wcCou,
        }
        this.$toast.clear()
      })
    },
    searchWaterReadFun() {
      if (!this.searchValue) {
        this.$toast('请输入姓名或户号')
        return false
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      getCbyWaterListApi({
        selectKey: this.searchValue,
      }).then((res) => {
        this.searchData = res.data
        this.isSearch = true
        this.$toast.clear()
      })
    },
    redirectsFun(item) {
      this.SET_CURR_MANUAL(item.cbscTitle)
      this.$router.push(`/waterList/${item.id}`)
    },
    refreshFun() {
      this.searchWaterReadFun()
      this.getManualDataFun()
    },
  },
}
</script>

<style lang="less" scoped>
#app {
  height: auto !important;
}
.meter-read-box {
  height: 100%;
  background-color: #f1f0f5;
}
.top-box {
  position: relative;
  background-color: #23a9fe;
  height: 70px;
  .top-title {
    font-size: 16px;
    color: #fff;
    padding-top: 10px;
  }
  .top-search {
    position: fixed;
    right: 20px;
    top: 12px;
    color: #fff;
    font-size: 20px;
    z-index: 999;
  }
}
.read-num-box {
  box-sizing: border-box;
  width: calc(100% - 40px);
  position: absolute;
  left: 20px;
  top: 55px;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  font-size: 16px;
  padding: 18px 0;
  border-radius: 10px;
  box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.2);
  .num-item {
    .item-text {
      padding-top: 10px;
    }
    &:nth-of-type(1) {
      .item-num {
        color: #3377ff;
      }
    }
    &:nth-of-type(2) {
      .item-num {
        color: #ffb54c;
      }
    }
    &:nth-of-type(3) {
      .item-num {
        color: #ff4c74;
      }
    }
  }
}
.read-list-box {
  margin-top: 50px;
  .read-null {
    padding-top: 20px;
    .null-text {
      font-size: 12px;
      color: #999;
    }
  }
}
.manual-list-box {
  .list-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 20px;
    margin-top: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    .item-icon {
      font-size: 24px;
      color: #23a9fe;
    }
    .item-content {
      flex: 1;
      text-align: left;
      padding-left: 10px;
      .content-text {
        font-size: 16px;
      }
      .content-tip {
        font-size: 12px;
        padding-top: 2px;
        color: #999;
        .tip-num {
          color: red;
        }
      }
    }
    .item-arrow {
      font-size: 16px;
    }
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
}
.search-pop-box {
  background-color: #efeff4;
  height: 100%;
  .pop-title {
    position: relative;
    font-size: 14px;
    color: #232323;
    height: 40px;
    box-sizing: border-box;
    padding-top: 10px;
    background-color: #6c9af5;
    color: #fff;
    text-align: center;
    .title-icon {
      position: absolute;
      right: 20px;
      top: 12px;
      color: #fff;
    }
  }
  .list-null {
    padding-top: 20px;
    color: #999;
  }
  .search-list {
    margin-top: 5px;
  }
}
</style>